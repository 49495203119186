<template>
  <CRow>
    <CCol col="12" lg="6">
      <CCard no-header>
        <CCardBody>
          <h3>
            Create Menu
          </h3>
          <CAlert :show.sync="dismissCountDown" color="primary" fade> ({{ dismissCountDown }}) {{ message }} </CAlert>

          <CInput label="Name" type="text" placeholder="Name" v-model="name"></CInput>

          <CButton color="primary" @click="store()">Create</CButton>
          <CButton color="primary" @click="goBack">Back</CButton>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
export default {
  name: 'CreateMenu',
  data: () => {
    return {
      name: '',
      message: '',
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: false
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
      // this.$router.replace({path: '/users'})
    },
    store() {
      let self = this
      axios
        .post(this.$apiAdress + 'admin/menu/menu/store?token=' + localStorage.getItem('api_token'), { name: self.name })
        .then(function(response) {
          self.name = ''
          self.message = 'Successfully created note.'
          self.showAlert()
        })
        .catch(function(error) {
          if (error.response.data.message == 'The given data was invalid.') {
            self.message = ''
            for (let key in error.response.data.errors) {
              if (error.response.data.errors.hasOwnProperty(key)) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
            self.showAlert()
          } else {
            console.error(error)
            self.$router.push({ path: 'login' })
          }
        })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  mounted: function() {}
}
</script>
